<template>
  <div class="row">
    <div class="col-md-12 text-dark">
      <div class="card">
        <div class="card-body">
          <h2>gallery.</h2>
          <details class="mt-4 mb-3">
            <summary>gamis</summary>
            <ul v-for="item in items" :key="item.id">
              <li>{{ item.name }}</li>
            </ul>
          </details>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: '',
    }
  },

  mounted() {
    this.bucket();
  },

  methods: {
    async bucket() {
      let { data, error } = await this.$supabase
        .storage
        .from("binarry")
        .list('gamis')

      if (data) this.items = data
    },
  },
};
</script>

<style scoped></style>
